*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  /* FONT SIZES */
  --fs-700: 1.5rem;
  --fs-500: 0.9rem;
  --fs-400: 0.65rem;


  /* FONT WEIGHTS */
  --fw-bold: 700;
  --fw-medium: 500;

  /* COLORS */
  --ff-primary: #F55A5A;
  --ff-gray: #918E9B;
  --ff-text: #2B283A;

 /* LETTER SPACINGS */
  --ls-wide: .17em;
  --ls-narrow: -0.075em;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
.flex {
  display: flex;
}
/* Set core body defaults */
body {
  /* min-height: 100vh; */
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: Inter, sans-serif;
}
/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}
nav {
  background:var(--ff-primary);
  justify-content: center;
  align-items: center;
  color: white; 
  padding: 20px;


}
.page-title {
  font-size: 14.46px;
  font-weight: var(--fw-medium);
  letter-spacing: var(--ls-narrow);
  line-height: 17.5px;
  margin-left: 5px;
}
.logo i {
  font-size: 1.5em;
}
.container {
  padding: 45px 40px 0 40px;
  align-items: center;
}
.--card-image {
  width: 125px;
  height: 168px;
}
.--card-image img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.--card-info {
  flex-direction: column;
  width: 70%;
  margin-left: 19px;
}
.--location-info {
  align-items: center;
}
.--location-icon {
  color: var(--ff-primary);
}
.--card-country {
  letter-spacing: var(--ls-wide);
  padding: 0 12px 0 4px;
  font-size: 10.24px;
}
.google-link {
  color: var(--ff-gray);
  font-size: 10.24px;
  /* text-decoration: none; */
}
.--card-title {
  font-size: 25px;
  margin-bottom: 16px;
}
.--card-date {
  font-weight: bold;
  font-size: 10.24px;
}
.--card-description {
  font-size: 10.24px;
  line-height: 15.36px;
}
